import dayjs from 'dayjs';

export function isDebug(): boolean {
  return process.env.NEXT_PUBLIC_DEBUG === '1';
}

export function getCurrentDateFormatted(): string {
  return dayjs().format('MM/DD/YYYY');
}

export function getPreviousDateFormatted(jobDays: number): string {
  return dayjs().subtract(jobDays, 'day').format('MM/DD/YYYY');
}
